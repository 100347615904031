// main page
.hero {
	margin-top: -9.285714285714286rem;
	position: relative;
	z-index: 1;
	color: #fff;
	&::before {
		content: "";
		background: rgba(19, 26, 28, 0.5);
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	&__wrapper {
		height: 100vh;
		min-height: 42rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 11.4375rem;
		padding-bottom: 5rem;
		padding-right: 46%;
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
		z-index: -2;
	}

	&__title {
		font-weight: bold;
		font-size: 4.5rem;
		line-height: 120%;
		color: #ffffff;
        height: 41vh;
        display: flex;
        align-items: center;
	}

	&__btn {
	}

	&__mouse {
		position: absolute;
		bottom: 5.1875rem;
		left: 49%;
		transform: translateX(-50%);
	}

	&__sidebar {
		background: $orange;
		width: 12rem;
		height: 100vh;
		min-height: 42rem;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
        z-index: 1;
		padding: 9.375rem 2.1875rem 3.625rem;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		&-logo {
			display: inline-block;
            max-width: 2.8125rem;
            width: 100%;
		}
	}
}

//main category tile
.category-tile {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	&__cursor {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 3;
		opacity: 0;
		transition: opacity 0.3s ease;
		transform: translate(-50%, -50%);
		background: $orange;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		width: 5.625rem;
		height: 5.625rem;
		font-weight: bold;
		font-size: 12px;
		line-height: 13px;
		pointer-events: none;
		cursor: none;
		overflow: hidden;
		border-radius: 100%;
	}
	&__item {
		padding: 3.25rem 3rem;
		min-height: 23.4375rem;
		background: #eee;
		color: #fff !important;
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		font-weight: bold;
		font-size: 2.5rem;
		line-height: 120%;
		transition: box-shadow 0.3s ease;
        background-repeat: no-repeat;
        background-size: cover;
		z-index: 1;
		cursor: none;
		text-decoration: none !important;
		&::before {
			content: "";
			background: rgb(0, 0, 0);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0.7;
			transition: opacity 0.3s ease;
		}
        @include r(1199) {
            font-size: 2rem;
        }

		&:hover {
			box-shadow: -2.1875rem 3.4375rem 6.25rem rgba(255, 107, 3, 0.7);
			z-index: 2;
			&::before {
				opacity: 0.4;
			}
		}
	}
	&:hover {
		.category-tile__cursor {
			opacity: 1;
		}
	}
}

// block facilities
.facilities {
	padding: 5.8125rem 3.625rem;
	padding-right: 7.75rem;
	background: #000000;
	color: #ffffff;
	position: relative;
	overflow: hidden;
    margin-bottom: 4.375rem;
	z-index: 1;
    @include r(1199) {
        padding: 5.8125rem 0;
    }
	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 33.33%;
		height: 100%;
		background: $orange;
		z-index: -1;
	}
	&__title {
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 140%;
		color: $orange;
	}
	&__grid {
		display: flex;
	}

    &__col {
        width: 53.2%;
    }

	&__content {
        width: 46.8%;
        &-title {
            font-weight: bold;
            @include text56;
            color: #FFFFFF;
            margin-bottom: 2rem;
        }
        &-text {
            font-weight: normal;
            font-size: 1rem;
            line-height: 120%;
            color: #FFFFFF;
        }
		&-slider {
			width: 100%;
            margin-top: 7.3125rem;
            margin-bottom: 3.3125rem;
            .swiper-slide {
                padding-right: 3.5rem;
            }
		}
	}

	&__image {
		width: 100%;

		img {
			width: 100%;
		}

		&-slider {
			width: 100%;
			box-shadow: -6.5625rem 4.6875rem 50rem rgba(255, 107, 3, 0.55);
		}
		// &::before {
		//     content: '';
		//     padding-top: 100%;
		// }
	}
    &__dots {
        width: 20.5rem;
        display: flex;
        align-items: center;
        margin: 1.75rem 0;
        .swiper-pagination-bullet {
            flex-grow: 1;
            opacity: 0.4;
            border-radius: 7.5rem;
            margin-right: 0.5rem;
            cursor: pointer;
            position: relative;
            padding: 1.25rem 0;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 100%;
                height: 0.25rem;
                background: #FF6B03;
            }
            &-active {
                opacity: 1;
            }
        }

    }
}



// simple-slider
.simple-slider {
    overflow: visible;
    &__block {
        padding: 2.5rem 0;
        overflow: hidden;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.25rem;
    }
    &__title {
        font-weight: bold;
        @include text56;
        color: #000000;
    }
    &__arrow {
        background: none;
        margin-right: 7.8125rem;
        outline: none !important;
        transition: opacity .2s ease;
        &.swiper-button-disabled {
            opacity: 0.5;
        }
        svg {
            fill: none;
            stroke: $orange;
            stroke-width: 0.25rem;
            height: 2.8125rem;
            width: 1.875rem;
        }
        &-prev {
            transform: rotate(180deg);
        }
    }
    &__card {
        min-height: 14rem;
        background: #F1F1F1;
        padding: 2rem 2rem 1.375rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 120%;
        color: #000;
        text-decoration: none !important;
        transition: background .3s ease, color .2s ease;
        &-icon {
            width: 5rem;
            height: 5rem;
            object-fit: contain;
            object-position: left top;
            margin-bottom: 1rem;
        }
        &:hover {
            background: #FFEFE4;
            color: $orange;
        }
    }
}
