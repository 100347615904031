.about {
    &-block {
        margin-bottom: 2rem;
    }
    &__title {
        @include text80;
        margin-bottom: 4rem;
    }
    &__text {
        @include text20;
        margin: 3.8rem 0;
    }
    &__grid {
        display: grid;
        grid-template-columns: 56% 1fr;
        gap: 1.5rem;
    }
    &__images {
        display: flex;
        gap: 3px;
        img {
            width: 100%;
            height: 15.125rem;
            object-fit: cover;
        }
    }
    &-logo {
        width: 18.5rem;
        margin-bottom: 4rem;
        &-text {
            width: 8.125rem;
        }
    }
}
.about-col_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3.9rem;
}
