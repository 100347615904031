.contacts {
    &__grid {
        display: grid;
        grid-template-columns: 31% 22.5% 1fr;
        gap: 2rem;
    }
    &__item {
        @include text20;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1.2rem;
        &-title {
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        &-phone {
            font-weight: 600;
            @include text20;
            color: #000000;
            margin-bottom: 0.5rem;

        }
        &-mail {
            font-weight: 600;
            @include text20;
            text-decoration: underline;
            color: $orange;

        }
    }

    &__map {
        width: 100%;
        padding: 2rem 0;
        img {
            width: 100%;
        }
    }
    &__people {
        padding: 5.4rem 0;
    }
    &__manager {
        max-width: 41.25rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        &-photo {
            width: 12rem;
            min-width: 12rem;
            height: 14rem;
            object-fit: cover;
            margin-right: 2.5rem;
        }
        &-content {
            padding-top: 1.1rem;
        }
        &-name {
            font-weight: bold;
            @include text40;
            color: #000000;
            margin-bottom: 0.7rem;
        }
        &-status {
            @include text16;
            color: #000000;
            opacity: 0.5;
            margin-bottom: 2rem;
        }
        &-footer {
            margin-top: 2.6rem;
            display: grid;
            grid-template-columns: 44% 56%;
            align-items: flex-end;
            .btn {
                text-align: center;
                justify-content: center;
            }
        }
        &-phone, &-mail {
            @include text16;
            color: #000;
        }
        &-mail {
            color: $orange;
        }
    }
}

#map {
    height: 26rem;
}

// .marker {
//     background: red;
//     width: 50px;
//     height: 50px;
// }
