.product {

    &-list {
        padding-bottom: 3.875rem;
        &__grid {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 5.8rem 2rem;
        }

    }
    &-item {
        text-decoration: none !important;
        &:hover {
            .product-item__name {
                color: $primary;
            }
        }
        &__image {
            width: 100%;
            height: 18.125rem;
            object-fit: contain;
            object-position: left center;
            margin-bottom: 1.4rem;
            // opacity: 0.3;
            transition: opacity .3s ease;
        }
        &__name {
            font-weight: 600;
            @include text20;
            color: #000000;
            margin-bottom: 0.5rem;
        }
        &__desc {
            font-weight: normal;
            @include text14;
            color: #000000;
            opacity: 0.8;
            margin-bottom: 1rem;
        }
        &__cost {
            font-style: normal;
            font-weight: 600;
            @include text20;
            color: $orange;

        }
    }
}
