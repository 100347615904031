// section page
.sections {
    &-block {
        padding: 0rem 0 4rem;
        margin-bottom: 2rem;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 2rem 2rem;
      &__item {
        background: #326CDB;
        padding: 2.5rem;
        color: #fff;
        transition: background .3s ease;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 12rem;
        overflow: hidden;
        &_large {
            justify-content: flex-start;
            &:hover {
                img {
                    transform: scale(1.075);
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            transition: transform .3s ease;
        }
        &:hover {
            text-decoration: none;
            color: #fff;
            b {
                padding-left: 0.75rem;
            }
            span {
                padding-left: 0.25rem;
            }
        }
        b, span {
            display: block;
            transition: padding-left .3s ease;
        }
        b {
            font-weight: 600;
            @include text24;
            margin-bottom: 0.7rem;
        }
        span {
            @include text16;
        }
        &:nth-child(1) {
            grid-row: 1 / 3;

        }
        &:nth-child(2) {
            grid-row: 1 / 3;
        }
      }
    }


}
