html,
:root {
  font-size: 9px;
  transition: font-size .15s ease;
  @include rmin(768) {
    font-size: 12px;
  }
  @include rmin(992) {
    font-size: 13px;
  }
  @include rmin(1199) {
    font-size: 14px;
  }
	@include rmin(1300) {
		font-size: 15px;
  }
	@include rmin(1360) {
		font-size: 16px;
  }
	@include rmin(1700) {
		font-size: 19px;
  }
}
html, body {
	overflow-x: hidden;
    min-height: 100%;
}
body {
	background: #fff;
	font-family: $font-primary;
	display: flex;
	flex-direction: column;
	@include text16;
	-webkit-font-smoothing: antialiased;
	user-select: none;
	// responsive
	// +css-lock(16, 50, 600, 1000)
}

.container-fluid {
    padding: 0 4rem;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}
.header {
	// height: 112px;
    position: relative;
    z-index: 5;
    padding: 2rem 0;
    min-height: 9.285714285714286rem;


    .hero__sidebar {
        display: none;
    }

    &_main {
        padding: 2.5rem 0.5625rem;
        .header__info-item {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
        .header__logo {
            max-width: 3.75rem;
            margin-right: 2.75rem;
        }
        .hero__sidebar {
            display: flex;
        }
        .header__menu-toggle_active span {
            background: #000;
        }

    }

    &__logo {
        margin-right: 3.875rem;
        transition: transform .25s ease;
        max-width: 2.5625rem;
        img {
            max-width: 100%;
        }
        &:hover {
            transform: scale(1.1);
        }
    }

    &__menu {
        overflow: hidden;
        &-toggle {
            position: relative;
            z-index: 2;
            background: transparent;
            transition: transform .25s ease;
            outline: none !important;
            width: 2rem;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            span {
                height: 0.25rem;
                background: #000;
                width: 100%;
                display: block;
                margin-bottom: 0.3125rem;
                transition: width .3s ease, background .3s ease, transform .5s ease, opacity .3s ease;
                &:last-child {
                    width: 70%;
                    margin-bottom: 0;
                }
            }
            &:hover {
                transform: scaleX(1.35);
                span {
                    &:last-child {
                        width: 100%;
                    }
                }
            }
            &:active {
                transform: scaleX(1.2);
            }

            &_active {
                transform: scale(1);
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
                span {
                    width: 2rem !important;
                    background: #fff;
                    &:nth-child(1) {
                        transform: translate(0.1rem,0.55rem) rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: translateX(50%);
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        width: 100%;
                        transform: translate(0rem,-0.55rem) rotate(-45deg);
                    }
                }
            }
        }

        &-list {
            opacity: 0;
            visibility: hidden;
            transform: translateX(100%);
            position: absolute;
            right: 0;
            top: 0;
            height: 100vh;
            min-height: 42rem;
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5rem 4.4375rem;
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 120%;
            color: #FFFFFF;
            background: #000;
            z-index: 1;
            transition: transform .3s ease, opacity .3s ease, visibility .3s ease;
            transition-delay: .15s;
        }
        &-item {
            color: #fff;
            transition: padding-left .15s ease;
            padding: 0.625rem 0;
            &:hover {
                color: #fff;
                text-decoration: none;
                padding-left: 1.25rem;
            }
        }
        &-overlay {
            opacity: 0;
            visibility: hidden;
            background: rgba(0, 0, 0, 0.6);
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            transition: opacity .3s ease, visibility .3s ease;
        }
        &_active {
            .header__menu-list {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
            }
            .header__menu-overlay {
                opacity: 1;
                visibility: visible;

            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        &-item {
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 120%;
            margin-right: 2.5rem;
            color: #000;
            text-decoration: none !important;
            transition: opacity .25s ease;
            &:hover {
                opacity: 0.6;
                color: #000;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__left {
        width: 70%;
        display: flex;
        align-items: center;
    }
    &__right {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
.scroll-down {
	.header {
		transform: translateY(-100%);
		opacity: 0;
		visibility: hidden;
	}
}
.footer {
    padding: 2rem 0 1.5rem;
    margin-top: 2rem;
    border: 2px solid rgba(0,0,0,0.1);

    &__logo {
        margin-right: 2.75rem;
        transition: transform .25s ease;
        &:hover {
            transform: scale(1.1);
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 6.125rem 1fr 9.375rem;
        grid-gap: 0 0.625rem;
    }

    &-top {
        &__nav {
            margin-bottom: 3.25rem;
            &-item {
                font-weight: 500;
                font-size: 1rem;
                line-height: 140%;
                color: #000000 !important;;
                margin-right: 2.3125rem;
            }
        }
    }
    &-bottom {
        &__info {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 160%;
            color: #000000;
            opacity: 0.8;
            display: flex;
            align-items: center;
        }
        &__link {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 160%;
            color: #000000;
            opacity: 0.8;
            margin-left: 2rem;
        }
    }
    &__author {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-bottom: 0.5rem;
        &-link {
            opacity: 0.3;
            transition: opacity .2s ease;
            &:hover {
                opacity: 1;
            }
        }
    }

}
.main {
	// padding: 20px 0 2.5rem;
	flex-grow: 1;
}


// buttons
.btn {
    padding: 0.75rem 1.375rem;
    border-radius: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 160%;
    color: #fff;
    text-decoration: none !important;
    position: relative;
    z-index: 1;
    transition: color .3s ease;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #060606;
        z-index: -1;
        border-radius: 3rem;
        transition: transform .4s ease;
    }
    &:hover {
        color: darken(#fff, 10%);
        &::after {
            transform: scale(1.25);
        }
    }

    &_dark::after {
        background: #000;
    }
    &_primary::after {
        background: $orange;
    }
    &_link {
        color: #000;
        @include text16;
        text-transform: none;
        font-weight: 400;
        &::after {
            content: none;
        }
        &:hover {
            color: lighten(#000, 20%);
        }
    }
    &_transparent {
        background: transparent;
        &::after {
            content: none;
        }
        outline: none !important;
        box-shadow: none !important;
        // &:hover {
        //     color: lighten(#000, 20%);
        // }
    }
    &_block {
        width: 100%;
    }
}

// widgets
.page-title-widget {
    margin: 1rem 0;
    padding-bottom: 0.5rem;
    max-width: 75%;
    @include rmin(1921) {
        margin: 1rem auto;
    }
}

.breadcrumb-item a {
    color: #666666;
}


.order-popup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(8px);
        transition: backdrop-filter .5s linear;
    }
    &__wrapper {
        overflow-y: auto;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        z-index: 1;
        pointer-events: none;
    }
    &__content {
        color: #fff;
        position: relative;
        z-index: 1;
        pointer-events: all;
        padding: 1.25rem;
        transform: translateY(30px);
        &-title {
            @include text24;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        &-theme {
            @include text16;
            text-align: center;
            margin-bottom: 0.5rem;
        }
    }
    &__form {
        margin: 2.5rem 0;
        &-input {
            height: 3rem;
            padding: 0.5rem;
            width: 100%;
            background: transparent;
            color: #fff;
            border-bottom: 2px solid rgba(255,255,255, 0.6);
            font-weight: 600;
            color: #fff;
            @include text24;
            margin-bottom: 2rem;
            &:focus,
            &:active {
                border-bottom: 2px solid $orange !important;
            }
            &::placeholder {
                color: rgba(255,255,255, 0.6);
            }
        }
    }
    &.hidden {
        backdrop-filter: blur(0px);
        pointer-events: none;
        visibility: hidden;
    }
}

.mapboxgl-popup-close-button {
    right: 10px !important;
    top: 5px !important;
    outline: none !important;
    background: none !important;
}

.btn {
    outline: none !important;
}

.pristine-error {
    // font-size: 14px;
    margin-top: -2rem;
    margin-bottom: 2rem;
    color: #ff6b03;
}
