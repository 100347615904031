// section page
.services {
    &-list {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 2rem;
    }
    &-block {
        padding-bottom: 2rem;
    }
}

.simple-slider__card_large {
    padding: 3.5rem 3rem 3rem;
    align-items: center;
    .simple-slider__card-icon {
        width: 100%;
        height: 13rem;
        margin-bottom: 5rem;
    }
}


// $orange
.services-slider {
    width: 100%;
    overflow: hidden;
    .swiper-pagination {
        width: 100%;
        height: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 3.1875rem;
        background: rgba(0,0,0,0.1)
    }
    .swiper-pagination-progressbar-fill {
        display: block;
        background: $orange;
        height: 100%;
        width: 100%;
        border-radius: 28px;
        transform-origin: left top;
    }
}

.service-slide {
    &__content {
        background: #F1F1F1;
        padding: 0.9rem 2rem 2rem;
        font-weight: 600;
    }
    &__image {
        margin-left: 1.4rem;
        margin-bottom: 1.2rem;
        width: 10rem;
        height: 10rem;
        object-fit: contain;
    }
    &__name {
        @include text20;
        margin-bottom: 0.5rem;
    }
    &__phone,
    &__site, {
        @include text18;
        margin-bottom: 0.5rem;
        display: block;
    }
    &__site {
        text-decoration: underline;
    }
}

.content-block_service  {
    padding-right: 30%;
}
