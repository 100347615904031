.product {

    &-card {
        margin-top: 3rem;
        padding-top: 2.5rem;
        &__grid {
            display: grid;
            grid-template-columns: 39.8% 1fr;
            gap: 8rem;
        }
        &__image {
            width: 100%;
            margin-bottom: 2rem;
        }
        &__title {
            @include text40;
            font-weight: 600;
            margin-bottom: 1.95rem;
        }
        &__option {
            display: flex;
            align-items: center;
            @include text20;
            margin: 1.4rem 0;
            img {
                width: 1.125rem;
                margin-right: 1.2rem;
            }
        }
        &__square {
            color: $orange;
        }
        &__advantages {
            &-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 1.6rem 0 2.2rem;
            }
            &-item {
                padding-left: 2.3rem;
                padding-bottom: 0.9rem;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0.4rem;
                    top: 0.5rem;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background: #000;
                }
                &:last-child {
                    padding-bottom: 0;
                }

            }
        }
        &__cost {
            color: $orange;
            @include text40;
            font-weight: 600;
            margin: 1.8rem 0;
        }
        &__controls {
            margin: 1.8rem 0;
            .btn {
                margin-right: 1rem;
            }
        }
    }
    &-gallery {
        margin: 2rem 0;
        padding: 1.6rem 0;
        &__list {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            gap: 0.5rem;
        }
        &__item {
            height: 8rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

.similar {
    &__title {
        font-weight: 600;
        @include text40;
        margin-bottom: 4.5rem;
    }
    &-products {
        padding: 1.2rem 0;
        margin: 2rem 0;
    }
}
