// +r(size)
// +r(991)

@include rmin(1921) {
    .header .container-fluid {
        max-width: none;
    }
}

@include r(1199) {
    .footer-top__nav-item {
        margin-right: 1.5rem;
    }
    .header__logo img,
    .footer__logo img {
        max-width: 100%
    }
    .hero__sidebar {
        width: 11rem;
    }
}

@include r(991) {
    .header_main {
        padding-left: 0;
        padding-right: 0;
    }
    .header__left {
        width: 75%;
    }
    .header__right {
        width: 25%;
    }
    .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .hero__wrapper,
    .hero__sidebar {
        height: 60rem;
    }
    .hero__sidebar {
        width: 6rem;
    }
    .header__menu-list {
        height: 60rem;
        width: 80%;
    }
    .header_main {
        padding: 1.4rem 0;
    }
    .category-tile__cursor {
        display: none !important;
    }
    .hero__title {
        font-size: 3.75rem;
    }
    .simple-slider__arrow {
        margin-right: 3.8125rem;
    }
    .content-block p {
        font-size: 14px;
    }
    .footer__grid {
        grid-template-columns: 6.125rem 1fr;
    }
    .footer__grid {
        display: flex;
        flex-wrap: wrap;
    }
    .footer__author {
        width: 100%;
    }
    .about__grid {
        display: grid;
        grid-template-columns: 76% 1fr;
        gap: 1.5rem;
    }
    .about-logo {
        width: 100%;
    }
    .sections-list {
        grid-template-columns: repeat(2,1fr);
    }
    .sections-list__item:nth-child(1) {
        grid-row: 1/3;
    }
    .sections-list__item:nth-child(2) {
        grid-row: 2/4;
        grid-column: 2/3;
    }
    .sections-list__item:nth-child(3) {
        grid-column: 2/3;
    }
    .sections-list__item:nth-child(4) {
        grid-column: 1/2;
    }
    .product-list__grid {
        grid-template-columns: repeat(2,1fr);
    }
    .product-card__grid {
        grid-template-columns: 51.8% 1fr;
        gap: 5rem;
    }
    .similar-products {
        .product-item {
            &:nth-child(3) {
                display: none;
            }
        }
    }
    .services-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .contacts__grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .contacts__manager {
        max-width: 44.25rem;
    }
}

@include r(767) {
    .header__info-item_order {
        display: none;
    }
    .header__menu-list {
        width: 100%;
        padding: 4rem 2rem;
    }
    .header_main .header__logo {
        margin-right: 2rem;
    }
    .header__left {
        width: 85%;
    }
    .header__right {
        width: 15%;
    }
    .header__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .header__info-item {
        margin: 0.4rem 0;
    }
    .hero__mouse {
        display: none;
    }
    .btn {
        font-size: 1.5rem;
        padding: 1.25rem;
        white-space: nowrap;
    }
    .category-tile {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .category-tile__item {
        padding: 3.25rem 2rem;
    }
    .facilities__grid {
        flex-wrap: wrap;
    }
    .facilities__content, .facilities__col {
        width: 100%;
    }
    .facilities__col {
        order: -1;
    }
    .facilities__image-slider {
        margin-bottom: 3.5rem;
    }
    .facilities__content-slider {
        margin-top: 3.3125rem;
        margin-bottom: 1.3125rem;
    }
    .btn:hover::after {
        transform: scale(1.15);
    }
    .facilities::after {
        width: 6rem;
    }
    .simple-slider__arrow {
        margin-right: 1.5rem;
    }
    .simple-slider__header {
        margin-bottom: 2.25rem;
    }
    .footer__grid {
        flex-direction: row-reverse;
        gap: 0;
    }
    .footer__logo {
        margin-right: 0;
        margin-left: 2rem;
        width: calc(20% - 2rem);
    }
    .footer__content {
        width: 80%;
    }
    .footer-top__nav, .footer-bottom__info {
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
    .footer-top__nav-item {
        font-size: 13px;
        margin-bottom: 12px;
    }
    .footer-bottom__info {
        margin-bottom: 2rem;
    }
    .footer__author {
        margin-bottom: 1rem;
        justify-content: flex-start;
    }
    .footer-bottom__link, .footer-bottom__rights {
        font-size: 11px;
        margin-bottom: 12px;
        margin-left: 0;
    }
    .breadcrumb-item {
        font-size: 12px;
    }
    .about-col_right {
        display: none;
    }
    .about__grid {
        grid-template-columns: 1fr;
    }
    .about__images img {
        height: 13.125rem;
    }
    .about__text {
        font-size: 14px;
    }
    .sections-block .sections-list {
        display: flex;
        flex-wrap: wrap;

    }
    .sections-block .sections-list .sections-list__item {
        width: 100%;
    }
    .sections-list__item {
        min-height: 16rem;
        justify-content: flex-start;
    }
    .sections-list__item_large {
        height: 330px;
        justify-content: flex-start;
    }
    .product-list__grid {
        grid-template-columns: 1fr;
        gap: 6rem;
    }
    .product-item__name, .product-item__desc, .product-item__cost {
        font-size: 1.75rem;
    }
    .product-item__image {
        height: auto;
        max-height: 350px;
    }
    .product-card__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .product-gallery__item {
        min-width: 8rem;
    }
    .product-gallery__list {
        overflow-x: auto;
    }
    .services-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .content-block_service {
        padding-right: 0;
    }
    .contacts__grid {
        grid-template-columns: 1fr;
    }
    .contacts__manager {
        flex-wrap: wrap;
    }
    .contacts__manager-photo {
        width: 25rem;
        min-width: 25rem;
        height: 25rem;
        margin-right: 2.5rem;
        margin-bottom: 2rem;
    }
    .contacts__manager-status, .contacts__manager-info, .contacts__manager-phone, .contacts__manager-mail {
        font-size: 12px;
    }
    .contacts__manager-phone, .contacts__manager-mail {
        display: block;
    }
    .contacts__manager-footer {
        margin-top: 2.6rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    #map {
        height: 350px;
    }
    .contacts__item-title {
        font-size: 13px;
    }
    .breadcrumb-item {
        padding-left: 0;
        padding-right: 0rem !important;
    }
}
