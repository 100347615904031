@include font('Montserrat', 'Montserrat-Regular', normal)
@include font('Montserrat', 'Montserrat-Medium', 500)
@include font('Montserrat', 'Montserrat-SemiBold', 600)
@include font('Montserrat', 'Montserrat-Bold', bold)
@include font('Montserrat', 'Montserrat-Black', 900)

$font-primary: 'Montserrat', sans-serif;


@mixin text80() {
    font-size: 5rem;
    line-height: 120%;
    @include r(991) {
        font-size: 4.5rem;
        line-height: 110%;
    }
}

@mixin text56() {
    font-size: 3.5rem;
    line-height: 120%;
    @include r(991) {
        font-size: 2.75rem;
    }
    @include r(767) {
        font-size: 2.25rem;
    }
}

@mixin text40() {
    font-size: 2.5rem;
    line-height: 120%;
}

@mixin text24() {
    font-size: 1.5rem;
    line-height: 120%;
}

@mixin text20() {
    font-size: 1.25rem;
    line-height: 195%;
}

@mixin text16() {
    font-size: 1rem;
    line-height: 180%;
}

@mixin text18() {
    font-size: 1.125rem;
    line-height: 140%;
}

@mixin text14() {
    font-size: 0.875rem;
    line-height: 120%;
}

.h1 {
    font-weight: bold;
    @include text56;
    color: #000000;
    margin-bottom: 3rem;
}



.content-block {
    padding: 5rem 0;

    p {
        @include text16;
        color: #000000;
        opacity: 0.5;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &_service {
        padding-top: 2rem;
        p {
            @include text20;
            color: #000000;
            opacity: 1;
        }
    }

}
