// +r(size)
// +r(991)

.mouse-wheel {
    animation: scroll-down 1.5s ease infinite;
}

@keyframes scroll-down {
    0% {
        transform: translateY(-0.25rem);
        opacity: 0;
    }
    50% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(0.125rem);
        opacity: 0;
    }
}
